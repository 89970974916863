<template>
  <div class="scientific main">
    <router-view></router-view>
  </div>

</template>

<script>
import { querySubMenus } from '@/api/index'
export default {
  components: {
  },
  data () {
    return {
      page: {
        pageNum: 1,
        pageSize: 15
      },
      total: 0,
      kxList: [],
      activedUrl: ''
    };
  },
  created () {
    // this.getTwoTree()
    // this.$router.push('/notice/2')
  },
  computed: {
  },
  watch: {
    // $route (to, from) {
    //   console.log(to, from)
    //   to.name != '详情' && this.getTwoTree(to.path == from.path)
    // }
  },
  methods: {
    // actived (item) {
    //   if (this.$route.params.id) {
    //     this.defaultActive = `/scientific/list/${this.$route.params.id}?configType=${item.configType}`
    //   } else {
    //     this.defaultActive = `/scientific/list/${this.$route.query.fid}?configType=${item.configType}`
    //   }
    //   if (this.$route.fullPath != this.defaultActive) {
    //     this.$router.push(this.defaultActive)
    //   }
    //   this.activedUrl = item.configType

    // },
    // getTwoTree (flag = false) {
    //   querySubMenus({
    //     fatherMenuType: this.$route.params.id,
    //     menuLevel: 2
    //   }).then(res => {
    //     this.kxList = res.data.list.map(v => {
    //       const configContent = v.configContent.split('|')
    //       if (configContent && configContent.length > 1) {
    //         v.configContent = configContent[0]
    //         v.url = configContent[1]
    //         v.type = 'url'
    //       } else {
    //         v.type = 'text'
    //       }
    //       return v
    //     })
    //     console.log(`${this.$route.params.id}`, this.activedUrl, this.$route.path);
    //     if (this.kxList && this.kxList.length > 0 && !flag) {
    //       this.defaultActive = `/scientific/list/${this.$route.params.id}?configType=${this.kxList[0].configType}`
    //       this.activedUrl = this.kxList[0].configType
    //       if (this.$route.path != this.defaultActive) {
    //         this.$router.replace(this.defaultActive)
    //       }
    //     }
    //     // console.log(this.defaultActive, 'defaultActive')
    //   })
    // },
    handleClick () { },
    handleSizeChange () { },
    handleCurrentChange () { }
  },
};
</script>

<style scoped lang="scss">
p {
  margin: 0px;
  padding: 0px;
}

</style>
